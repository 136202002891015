<template>
    <div class="container">
        <qy-top-nav title="修改登录密码" />
        <van-cell-group>
            <van-field v-model="oldPassword" type="password"  label="旧密码" label-width="5em" placeholder="请输入旧密码" autocomplete="off" :rules="[{ required: true, message: '请输入旧密码'}]"/>
            <van-field v-model="newPassword" type="password"  label="新密码" label-width="5em" placeholder="请输入新密码" autocomplete="off" :rules="[{ required: true, message: '请输入新密码'}]"/>
            <van-field v-model="newPassword2" type="password"  label="确认密码" label-width="5em" placeholder="请再次输入新密码" autocomplete="off" :rules="[{ required: true, message: '请再次输入新密码'}]"/>
            <br/>
            <van-button type="info" block round  @click="editPassword">确认修改</van-button>
        </van-cell-group>
    </div>
</template>

<script>
    import request from '@/utils/request'
    import { Toast } from 'vant';
    export default {
        name: "EditPwd",
        data() {
            return {
                newPassword:'',
                newPassword2:'',
                oldPassword:'',
            }
        },
        methods:{
            editPassword(){
                if(!(this.newPassword) || !(this.newPassword2) || !(this.oldPassword)){
                    return Toast.fail("密码不能空！");
                }
                if (this.newPassword != this.newPassword2){
                    return Toast.fail("新密码两次输入不一致");
                }
                request.post("/api/mine/editPassword",{
                    newPassword:this.newPassword,
                    oldPassword:this.oldPassword
                }).then(response => {
                    if(response.code == 0){
                        //重新登录........
                        this.$router.push("/login");
                    }else {
                        return Toast.fail(response.msg);
                    }
                });
            }
        }
    }
</script>

<style scoped>
.van-button--info{

	background: -webkit-linear-gradient(left,#ff6034,#ee0a24);
    background: linear-gradient(to right,#ff6034,#ee0a24);
	border:0px;
	width:95%;
	margin:0px auto;
}
</style>